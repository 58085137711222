import React from "react";

import { Grid } from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import { useDispatch } from "react-redux";

import { deleteSaleLot } from "actions";

import { SubtleBadge } from "components/Badge";
import SlimButton, { SlimSecondaryButton } from "components/Button/SlimButton";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { FormCollapse } from "components/Form";
import { Input, Label } from "components/Form/FormikControls";

import { calculateTotalPriceCents, formatDecimal } from "lib";
import { Row } from "components/Layout";

import { summarizeLots } from "./lib";

import { FormValues } from "./types";

const SkinsSummary = () => {
  const { values } = useFormikContext<FormValues>();

  const { saleLots, skinsLots } = values;

  const summary = summarizeLots([...saleLots, ...skinsLots]);
  const { averageDollarsPerKilo, dollarsPerHead, totalPriceDollars } = summary;

  const skinsSummary = summarizeLots(skinsLots);

  return (
    <>
      {skinsLots.length > 1 && (
        <>
          <Grid item xs={4}>
            <Input
              disabled
              name="totalSkins"
              align="right"
              label="Total Skins"
              overrideValue={skinsSummary.totalQuantity}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              disabled
              name="averageSkinsPrice"
              align="right"
              beforeSymbol="$"
              label="Avg Skin Price"
              overrideValue={formatDecimal(skinsSummary.dollarsPerHead)}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              disabled
              name="totalSkinsValue"
              align="right"
              beforeSymbol="$"
              label="Total Skin Value"
              overrideValue={formatDecimal(skinsSummary.totalPriceDollars)}
            />
          </Grid>
        </>
      )}

      <Grid item xs={4}>
        <Input
          disabled
          name="averagePricePerKgSkins"
          align="right"
          beforeSymbol="$"
          label="Avg $/kg (inc skins)"
          overrideValue={averageDollarsPerKilo}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="dollarsPerHeadSkins"
          align="right"
          beforeSymbol="$"
          label="$/Hd (inc skins)"
          overrideValue={formatDecimal(dollarsPerHead)}
        />
      </Grid>

      <Grid item xs={4}>
        <Input
          disabled
          name="totalPriceSkins"
          beforeSymbol="$"
          align="right"
          label="Total (inc skins)"
          overrideValue={formatDecimal(totalPriceDollars)}
        />
      </Grid>
    </>
  );
};

const SkinLotEntry =
  (handleClickDelete: { (saleLot: any, index: any): void }) =>
  (skinsLot, index) => {
    const skinValue = calculateTotalPriceCents(skinsLot) || 0;

    return (
      <React.Fragment key={skinsLot.id || index}>
        <Grid item xs={4}>
          <Input
            label={index === 0 ? "Skin Quantity" : undefined}
            name={`skinsLots[${index}]quantity`}
            required={index === 0}
            type="number"
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            label={index === 0 ? "Skin Price" : undefined}
            name={`skinsLots[${index}]unitPrice`}
            beforeSymbol="$"
            required={index === 0}
            type="number"
            decimal
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            label={index === 0 ? "Skin Value" : undefined}
            name="skinValue"
            beforeSymbol="$"
            disabled
            overrideValue={formatDecimal(skinValue / 100)}
            align="right"
          />
        </Grid>
        <Grid item xs={1} justifyContent="center" alignItems="center" container>
          {index === 0 && <Label>&nbsp;</Label>}
          <SlimSecondaryButton
            type="button"
            color="red"
            fullWidth
            onClick={() => handleClickDelete(skinsLot, index)}
            disabled={index === 0 && !skinsLot.id}
          >
            Delete
          </SlimSecondaryButton>
        </Grid>
      </React.Fragment>
    );
  };

export const SkinsForm = ({ defaultSkinsLot }: any) => {
  const formikProps = useFormikContext<FormValues>();
  const { values } = formikProps;

  const { skinsLots } = values;
  const [confirmDeleteSaleLot, setConfirmDeleteSaleLot] = React.useState(null);

  const removeSaleLotAtIndex = index => {
    const nextValue = values.skinsLots.filter((_s, i) => i !== index);
    // If there is nothing left, add the default.
    const nextOrDefault =
      nextValue.length === 0 ? [defaultSkinsLot] : nextValue;

    formikProps.setFieldValue("skinsLots", nextOrDefault);
  };

  const closeConfirmDialog = () => setConfirmDeleteSaleLot(null);

  const handleClickDelete = (saleLot, index) => {
    if (!saleLot.id) {
      removeSaleLotAtIndex(index);
    } else {
      setConfirmDeleteSaleLot({ saleLot, index });
    }
  };
  const dispatch = useDispatch();
  const handleDelete = () => {
    const { saleLot, index } = confirmDeleteSaleLot;
    dispatch(deleteSaleLot(saleLot));
    removeSaleLotAtIndex(index);
    closeConfirmDialog();
  };

  return (
    <FormCollapse header={<SubtleBadge>Skins</SubtleBadge>}>
      <FieldArray name="skinsLots">
        {arrayHelpers => (
          <>
            {skinsLots.map(SkinLotEntry(handleClickDelete))}
            <Grid item xs={12}>
              <Row justifyEnd>
                <SlimButton
                  onClick={() => arrayHelpers.push(defaultSkinsLot)}
                  type="button"
                >
                  Add line
                </SlimButton>
              </Row>
            </Grid>
          </>
        )}
      </FieldArray>
      <SkinsSummary />
      <ConfirmDialog
        title={createModalTitle("this item")}
        isOpen={confirmDeleteSaleLot}
        onCancel={closeConfirmDialog}
        onDelete={handleDelete}
      />
    </FormCollapse>
  );
};
