import React from "react";

import { sortBy } from "lodash";
import { useSelector } from "react-redux";

import { Label } from "components/Form/FormikControls";
import { Column } from "components/Layout";

import { getDressingRanges, getWeightRangeBySaleRoundId } from "selectors";

import { useFieldValue } from "hooks";

const { OptionTogglerField } = require("components/Form/FormikControls");

export const DressingRangeField = ({
  name,
  disabled,
  showNeedsRoundMessage = false,
}) => {
  const saleRoundId = useFieldValue("sale_round_id");

  const weightRanges = useSelector(getWeightRangeBySaleRoundId(saleRoundId));

  const dressingRanges = useSelector(getDressingRanges);
  const dressingRangeOptions = sortBy(
    Object.values(dressingRanges),
    "order",
  ).map(range => {
    let dressingLabel = `${parseInt(
      range.dressingRangeMinPercent,
      10,
    )}-${parseInt(range.dressingRangeMaxPercent, 10)}%`;
    if (!range.dressingRangeMaxPercent) {
      dressingLabel = `${range.dressingRangeMinPercent}+%`;
    }
    return {
      label: dressingLabel,
      value: range.id,
    };
  });

  if (dressingRangeOptions.length > 0 && weightRanges.length !== 0) {
    return (
      <OptionTogglerField
        labelPosition="top"
        name={name}
        label="Est Dressing %"
        options={dressingRangeOptions}
        disabled={disabled}
      />
    );
  } else if (showNeedsRoundMessage) {
    return (
      <Column>
        <Label>Est Dressing %</Label>
        <div>Select a Sale Round to see available Est Dressing %.</div>
      </Column>
    );
  } else {
    return null;
  }
};
