import React, {
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useSelector } from "react-redux";

import AddBuyerWayModal from "components/AuctionPenSaleView/AddBuyerWayModal";
import BuyerPicker from "components/AuctionPenSaleView/BuyerPicker";
import BuyerSearchModal from "components/AuctionPenSaleView/BuyerSearchModal";
import {
  KeypadInput,
  PricingSelect,
} from "components/AuctionPenSaleView/PricingInput";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";

import WeighBridgeCurrentLotSummary from "containers/WeighbridgeSaleView/WeighbridgeCurrentLotSummary";

import { EMPTY_OBJECT, getUnitPriceString } from "lib";

import { getDefaultPropertyId } from "lib/properties";

import {
  getAgencyIdByConsignmentId,
  getBusinessById,
  getCurrentSaleyard,
  getSaleLotById,
  getVendorIdBySaleLotId,
} from "selectors";

import {
  WeighBridgeSaleDispatchContext,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";

function CurrentLotAuctionViewComponent(props) {
  const { isSellingDetailsDirty, saleLotId, sellingDetails } = props;

  const { buyerId, propertyId, buyerWayId, price, pricingTypeId } =
    sellingDetails;

  const { updateSaleLotSellingDetails } = useContext(
    WeighBridgeSaleDispatchContext,
  );

  const saleLot = useSelector(getSaleLotById(saleLotId)) || EMPTY_OBJECT;
  const agencyId = useSelector(
    getAgencyIdByConsignmentId(saleLot.consignment_id),
  );
  const vendorId = useSelector(getVendorIdBySaleLotId(saleLotId));
  const buyer = useSelector(getBusinessById(buyerId)) || EMPTY_OBJECT;
  const saleyard = useSelector(getCurrentSaleyard);

  const [isBuyerPickerVisible, setIsBuyerPickerVisible] = useState(false);
  const [isCreateBuyerWayVisible, setIsCreateBuyerWayVisible] = useState(false);
  const [bidderFilterValue, setBidderFilterValue] = useState("");
  const [selectedInputEl, setSelectedInputEl] = useState(null);
  const [selectedInput, setSelectedInput] = useState(null);

  const prevSaleLotIdRef = useRef(saleLotId);
  const saleLotIdsList = useMemo(() => [saleLotId], [saleLotId]);

  // If the saleLot is updated in state for any reason (Eg we have saved the lot with a temp buyer way id and the
  // response contained a real id) update the form context.  This is not quite ideal because we COULD have a change in
  // the form that gets overridden because of pusher.
  const saleLotBuyerWayId = saleLot?.buyer_way?.id;
  useEffect(
    () =>
      updateSaleLotSellingDetails(
        {
          buyerWayId: saleLotBuyerWayId,
        },
        false,
      ),
    [saleLotBuyerWayId, updateSaleLotSellingDetails],
  );

  // Update (or clear) delivery property on buyer way (or buyer) change
  useEffect(() => {
    let defaultPropertyId;
    if (buyer?.buyerWays?.length && buyerWayId) {
      defaultPropertyId = getDefaultPropertyId(
        buyer,
        saleyard.id,
        buyer.buyerWays.find(buyerWay => buyerWay.id === buyerWayId)?.name,
      );
    }
    updateSaleLotSellingDetails(
      {
        propertyId: defaultPropertyId,
      },
      false,
    );
  }, [buyerWayId, updateSaleLotSellingDetails, buyer, saleyard]);

  useEffect(() => {
    // we want to ignore changes to the selected sale lot if the user has made changes,
    // unless they have changed Sale Lots, then we want to replace any entered values
    // with the ones from the selected Sale Lot

    const hasSaleLotIdChanged = saleLotId !== prevSaleLotIdRef.current;
    const isFormClean = saleLotId && !isSellingDetailsDirty;
    if (hasSaleLotIdChanged && isFormClean) {
      const buyerWay = saleLot.buyer_way;
      updateSaleLotSellingDetails(
        {
          buyerId: saleLot.buyer_id,
          // using && allows the property to automatically fall back to null when there is not buyer way set
          buyerWayId: buyerWay && buyerWay.id,
          price: getUnitPriceString(saleLot),
          pricingTypeId: saleLot.pricing_type_id,
          propertyId: saleLot.destination_property_id,
        },
        false,
      );
    }

    // always make sure the most recent selected Sale Lot Id is stored
    prevSaleLotIdRef.current = saleLotId;
  }, [
    isSellingDetailsDirty,
    saleLotId,
    prevSaleLotIdRef,
    saleLot,
    updateSaleLotSellingDetails,
    buyerWayId,
  ]);

  useLayoutEffect(() => {
    function onKeyPress(e) {
      if (e.key !== "Delete" && e.key !== "Enter") {
        if (selectedInput === KeypadInput.BIDDER_SEARCH) {
          setBidderFilterValue(bidderFilterValue + e.key);
        } else {
          const asciiValue = e.key.charCodeAt(0);
          if ((asciiValue > 47 && asciiValue < 58) || asciiValue === 46) {
            const priceString =
              (!price.includes(".") && asciiValue === 46) || asciiValue !== 46
                ? `${price}${e.key}`
                : price;
            updateSaleLotSellingDetails({
              price:
                price.toString().charAt(0) === "0"
                  ? priceString.slice(1)
                  : priceString,
            });
          }
        }
      }
    }

    function onKeyUp(e) {
      if (e.key === "Backspace") {
        if (selectedInput === KeypadInput.BIDDER_SEARCH) {
          setBidderFilterValue(bidderFilterValue.slice(0, -1));
        } else {
          updateSaleLotSellingDetails({ price: price.slice(0, -1) || "0" });
        }
      }
    }

    if (selectedInputEl !== null) {
      selectedInputEl.addEventListener("keypress", onKeyPress);
      selectedInputEl.addEventListener("keyup", onKeyUp);
    }

    return () => {
      if (selectedInputEl !== null) {
        selectedInputEl.removeEventListener("keypress", onKeyPress);
        selectedInputEl.removeEventListener("keyup", onKeyUp);
      }
    };
  }, [
    selectedInput,
    selectedInputEl,
    bidderFilterValue,
    price,
    setBidderFilterValue,
    updateSaleLotSellingDetails,
  ]);

  function openAddBuyerWay() {
    setIsCreateBuyerWayVisible(true);
  }

  function closeAddBuyerWay() {
    setIsCreateBuyerWayVisible(false);
  }

  function closeSearch() {
    setIsBuyerPickerVisible(false);
  }

  function openSearch() {
    setIsBuyerPickerVisible(true);
  }

  function onBlurBidderSearch() {
    setSelectedInput(null);
  }

  function onFocusBidderSearch(e) {
    setSelectedInput(KeypadInput.BIDDER_SEARCH);
    setSelectedInputEl(e.currentTarget);
  }

  function onSelectBuyerWay(buyerWayId) {
    updateSaleLotSellingDetails({
      buyerWayId,
    });
  }

  function onSelectPricingTypeId(pricingTypeId) {
    updateSaleLotSellingDetails({
      pricingTypeId,
    });
  }

  function onSelectInput(inputName, e) {
    setSelectedInputEl(e.currentTarget);
  }

  if (!saleLotId) {
    return null;
  }

  return (
    <>
      <WeighBridgeCurrentLotSummary saleLotId={saleLotId} />
      {typeof pricingTypeId === "number" && (
        <PricingSelect
          isSingleLot
          price={price}
          selectedHdCount={saleLot.quantity}
          selectedInput={selectedInput}
          selectedPricingTypeId={pricingTypeId}
          setSelectedInput={onSelectInput}
          setSelectedPricingType={onSelectPricingTypeId}
          totalHdCount={saleLot.quantity}
        />
      )}
      <BuyerPicker
        agencyId={agencyId}
        bidderSearchValue={bidderFilterValue}
        buyerId={buyerId}
        buyerWayId={buyerWayId}
        isBidderSearchSelected={selectedInput === KeypadInput.BIDDER_SEARCH}
        onBlurBidderNumberSearch={onBlurBidderSearch}
        onFocusBidderNumberSearch={onFocusBidderSearch}
        onOpenAddBuyerWay={openAddBuyerWay}
        onSelectBuyerValues={updateSaleLotSellingDetails}
        openSearch={openSearch}
        propertyId={propertyId}
        saleLotIds={saleLotIdsList}
        vendorId={vendorId}
      />

      {isCreateBuyerWayVisible && (
        <AddBuyerWayModal
          agencyId={agencyId}
          buyerId={buyerId}
          setBuyerWayId={onSelectBuyerWay}
          closeSelf={closeAddBuyerWay}
        />
      )}
      {isBuyerPickerVisible && (
        <BuyerSearchModal
          agencyId={agencyId}
          setBuyerValues={updateSaleLotSellingDetails}
          closeSelf={closeSearch}
        />
      )}
    </>
  );
}

const CurrentLotAuctionView = memo(CurrentLotAuctionViewComponent);

function StateAdapter() {
  const { selectedSaleLotId, sellingDetails, isSellingDetailsDirty } =
    useContext(WeighBridgeSaleStateContext);

  return (
    <WaitForSync requiredData={[ApiModel.CONSIGNMENTS, ApiModel.BUSINESSES]}>
      <CurrentLotAuctionView
        isSellingDetailsDirty={isSellingDetailsDirty}
        saleLotId={selectedSaleLotId}
        sellingDetails={sellingDetails}
      />
    </WaitForSync>
  );
}

export default memo(StateAdapter);
